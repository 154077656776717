import { CustomerOnBoardedType } from "./fff.enums";
import { SearchFilterBase } from "./shared-dto.types";

export interface SeasonalDiscountCodesState {
  items?: DiscountCode[];
  total?: number;
  selectedItem?: DiscountCode;
}

export interface DiscountCode {
  id?: string;
  code?: string;
  firstTimeOrderOnly?: boolean;
  expiryDateUtc?: Date;
  discountPercentage?: number;
  discountAmount?: number;
  bespokeFlexiDiscountValue?: number;
  ownerId?: string;
  ownerName?: string;
  loyaltyTokensOffer?: number;
  tokenEncashable?: boolean;
  type?: DiscountCodeType;
  platform?: DiscountCodePlatform;
  inactive?: boolean;
  createdDateUtc?: Date;
  usageCount?: number;
  minApplicablePackage?: string;
  discountCategory?: string,
  maxUsageCount?: number,
  applicablePaymentOption?: DiscountApplicablePaymentOption,
  waiverType: WaiverType,
  waiverSetting?: WaiverSetting,
  salesChannel?: CustomerOnBoardedType,
  applicablePackageIds?: string[],
  applicableFromDate?: Date,
  applicableToDate?: Date,
  applicableDaysFromOrderCreation?: number,
  orderPlacementOnly?: boolean;

  //FoodBox
  foodBoxDiscountValue?: number;
  foodBoxMaxDiscountValue?: number;
  foodBoxAutoApply?: boolean;
  foodBoxIndefiniteApply?: boolean;
}

export interface DiscountCodeSearchResults {
  items: DiscountCode[];
  total: number;
}

export interface WaiverSetting {
  noOfJuiceWaivers: number,
  noOfSnackWaivers: number,
  noOfBoosterWaivers: number,
  noOfShakeWaivers: number
}

export interface DiscountCodeSearchFilter extends SearchFilterBase {
  type?: DiscountCodeType;
  includeExpired?: boolean;
  includeInactive?: boolean;
}

export enum DiscountCodeType {
  Seasonal = 0,
  Affiliate = 1,
  Referral = 2,
  Sales = 3,
  FrozenFood = 4,
  ToGo = 5,
  F45 = 6,
  Flexi = 7,
  Ambassador = 8
}

export enum DiscountCodePlatform {
  Any = 0,
  Web = 1,
  Mobile = 2
}

export interface DiscountCodeInfo {
  id: string,
  code: string;
  valid: boolean;
  discountAmount: number;
  discountPercentage: number;
  bespokeFlexiDiscountValue?: number;
  waiverType: WaiverType
}

export interface DiscountCodeUsageDto {
  customerNo: string;
  customerEmail: string;
  customerName: string;
  orderNo: string;
  discountAmount: number;
  usedDate: Date;
}

export interface UpdateTokensFormValues {
  discountCodeType: DiscountCodeType;
  tokens: number;
}

export enum DiscountCategory {
  Platform = 0,
  PerCustomer = 1
}

export enum DiscountApplicablePaymentOption {
  Upfront = 0,
  Weekly = 1,
  Both = 2,
  Flexi = 3
}

export enum WaiverType {
  Amount = 0,
  Percentage = 1,
  Extras = 2
}

export enum WaiverExtraType {
  Juice = 0,
  Booster = 1,
  Snack = 2,
  Shake = 3
}

export interface DiscountCodeUsageDto {
  discountCode: string;
  usageCount: number;
  totalUsageAmount: number;
  discountCodeUsage: DiscountCodeUsageDto[]
}


export interface DiscountSignUpDetailsDto {
  customerNo: number;
  createdDateUtc: Date;
  customerName: string;
  discountCodeType: DiscountCodeType;
  discountAmount: number;
  discountPercentage: number;
}